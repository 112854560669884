import React, { useRef } from "react";
import '../../styles/Card.css';

import Card from "../CardNoPrice.js";
import CardFrom from "../CardFrom.js";
import CardU from "../CardA.js";
import Card2 from "../CardNoPriceNobutton.js";
import ScrollButtons from "../ScrollButtons.js";

import { useTranslation } from "react-i18next";

const CardS = () => {
  const { t } = useTranslation();
  const scrollRef = useRef(null);
  return (
    <>
      <div ref={scrollRef} className="cardSection centeredCards gorilla">
        <CardU
          h2={"2daanim"}
          text={"2daanimText"}
          price='170'
          per={t('perUnit')}
          text1={"2daanimText1"}
          text3={"2daanimText3"}
          text2={"2daanimText2"}
          text4={"2daanimText4"}
        />

        <CardU
          h2={"2daanim2"}
          text={"2daanim2Text"}
          price='200+'
          per={t('perUnit')}
          text1={"2daanim2Text1"}
          text3={"2daanim2Text3"}
          text2={"2daanim2Text2"}
          text4={"2daanim2Text4"}
        />
       <CardFrom
          h2={"3daanim"}
          text={"3daanimText"}
          price1='2500'
          price2='6000'
          per={t('perUnit')}
          text1={"3daanimText1"}
          text3={"3daanimText2"}
          text2={"3daanimText3"}
          text4={"3daanimText4"}
        />
        <CardFrom
        h2={"3daanimI"}
        text={"3daanimIText"}
        price1='1000'
        price2='1500'
        per={t('perUnit')}
        text1={"3daanimIText1"}
        text2={"3daanimIText2"}
      />
      <CardU
          h2={"picDesign"}
          text={"picDesignText"}
          price='35'
          per={t('perDesign')}
          text1={"picDesignText1"}
          text3={"picDesignText3"}
          text2={"picDesignText2"}
          text4={"picDesignText4"}
        />

      </div>
      <div className="card5">
        <ScrollButtons scrollRef={scrollRef} />
      </div>
    </>
  );
};

export default CardS;
