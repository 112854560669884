import React, { useRef } from "react";
import '../../styles/Card.css';

import Card from "../CardNoPrice.js";
import ScrollButtons from "../ScrollButtons.js";

import { useTranslation } from "react-i18next";

const Commentaries = () => {
  const { t } = useTranslation();
  const scrollRef = useRef(null);
  return (
    <>
    <h2 className="depprice">{t('dependedPrice')}</h2>
      <div ref={scrollRef} className="cardSection centeredCards gorilla">

        <Card
          // h1='bestOffer'
          // className='top'
          h2="YouTube"
          text={"youTubeText"}
          // negotiable={"negotiable"}
          // crossedPrice='3000'
          // price='negotiable'
          text1={"youTubeText1"}
          text2={"youTubeText2"}
          text3={"youTubeText3"}
          text4={"youTubeText4"}
          text5={"youTubeText5"}
          text6={"youTubeText6"}
        />

        <Card
          h2="Twitter"
          text={"twitter2Text"}
          // negotiable={"negotiable"}
          text1={"twitter2Text1"}
          text2={"twitter2Text2"}
          text3={"twitter2Text3"}
          text4={"twitter2Text4"}
          text5={"twitter2Text5"}
          text6={"twitter2Text6"}
          text7={"twitter2Text7"}
        />
         <Card
          h2="Telegram"
          text={"telegrammText"}
          // negotiable={"negotiable"}
          text1={"telegrammText1"}
          text2={"telegrammText2"}
          text3={"telegrammText3"}
          text4={"telegrammText4"}
          text5={"telegrammText5"}
        />
        <Card
          h2="TikTok"
          text={"ttText"}
          // negotiable={"negotiable"}
          text1={"ttText1"}
          text2={"ttText2"}
          text3={"ttText3"}
          text4={"ttText4"}
          text5={"ttText5"}
        />
        <Card
          h2="Instagram"
          text={"instText"}
          // negotiable={"negotiable"}
          text1={"instText1"}
          text2={"instText2"}
          text3={"instText3"}
          text4={"instText4"}
          text5={"instText5"}
        />
      </div>
      <div className="card5">
        <ScrollButtons scrollRef={scrollRef} />
      </div>
    </>
  );
};

export default Commentaries;
