import React from "react";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../styles/ClientsLogos.css";
import Marquee from "react-fast-marquee";

import logo1 from "../assets/icons/Khaby.svg";
import logo2 from "../assets/icons/TomoCat.svg";
import logo3 from "../assets/icons/ZimaBank.svg";
import logo4 from "../assets/icons/TonMole.svg";
import logo6 from "../assets/icons/LayerAI.svg";
import logo7 from "../assets/icons/BlueWhale.svg";
import logo8 from "../assets/icons/Choice.svg";
import logo9 from "../assets/icons/BenDog.svg";


const logos = [logo1, logo2, logo3, logo4, logo6, logo7, logo8, logo9];

const ClientsLogos = ({ direction }) => {
  return (
    <Marquee direction={direction} speed={50} gradient={false} className="">
      {logos.map((logo, index) => (
        <img key={index} src={logo} alt={`logo-${index}`} className="logo2" />
      ))}
    </Marquee>
  );
};


export default ClientsLogos;
