import React from 'react';
import "../styles/RevCard.css"

const RevCard = ({ img, h3, h1, text }) => (

    <div className='revCard'>
        <div className='upside'>

            <img src={img} alt='User' />

            <div className='head'>
                <h3>{h3}</h3>
                <h1>{h1}</h1>
            </div>
            <div className='svg'>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.13254e-07 2.08859C2.13254e-07 1.53471 0.220041 1.00351 0.611776 0.611779C1.00351 0.220044 1.53471 3.3967e-06 2.08871 3.3967e-06H22.9091C23.1836 -0.00049247 23.4555 0.0533086 23.7093 0.15806C23.963 0.262812 24.1936 0.416655 24.3877 0.610663C24.582 0.804671 24.7361 1.035 24.8411 1.28864C24.9462 1.54227 25.0001 1.81413 25 2.08859V22.9091C25.0002 23.1836 24.9464 23.4556 24.8416 23.7093C24.7367 23.963 24.5827 24.1936 24.3886 24.3878C24.1946 24.582 23.964 24.7361 23.7104 24.8411C23.4567 24.9461 23.1848 25.0001 22.9102 25H2.08871C1.81425 25 1.54264 24.9459 1.28913 24.8408C1.03562 24.7358 0.805287 24.5819 0.611403 24.3878C0.417395 24.1937 0.263553 23.9634 0.158677 23.7099C0.0538009 23.4562 -0.000123753 23.1846 2.13254e-07 22.9102V2.08859ZM9.89552 9.5318H13.2807V11.2318C13.7694 10.2545 15.0193 9.37499 16.8978 9.37499C20.4989 9.37499 21.3523 11.3216 21.3523 14.8931V21.5091H17.7079V15.7068C17.7079 13.6727 17.2193 12.525 15.9784 12.525C14.2568 12.525 13.5409 13.7625 13.5409 15.7068V21.5091H9.89552V9.5318ZM3.64549 21.3534H7.29098V9.37499H3.64549V21.3534ZM7.81251 5.46817C7.81945 5.78032 7.76391 6.09061 7.64912 6.38107C7.53445 6.6714 7.36288 6.93594 7.14457 7.15908C6.92627 7.38235 6.66557 7.55962 6.37784 7.68073C6.08999 7.80173 5.78094 7.86408 5.46879 7.86408C5.15652 7.86408 4.84747 7.80173 4.55974 7.68073C4.27202 7.55962 4.01132 7.38235 3.79289 7.15908C3.57458 6.93594 3.40313 6.6714 3.28834 6.38107C3.17367 6.09061 3.11813 5.78032 3.12508 5.46817C3.13847 4.85553 3.39136 4.27252 3.82946 3.84396C4.26755 3.41541 4.8559 3.17553 5.46879 3.17553C6.08156 3.17553 6.67003 3.41541 7.10813 3.84396C7.54623 4.27252 7.79899 4.85553 7.81251 5.46817Z" fill="#FF6C01" />
                </svg>
            </div>

        </div>
        <div className='downside'>
            <p>{text}</p>
        </div>

    </div>

);

export default RevCard;