import React from 'react';

const PdfLink = () => {
  return (
    
    <a className='navbar-item' href= '/cases.pdf' target="_blank" rel="cases">
      Cases
    </a>
  );
};

export default PdfLink;
