import React from "react";
import Marquee from "react-fast-marquee";
import logo1 from "../assets/icons/logo1.svg";
import logo2 from "../assets/icons/logo2.svg";
import logo3 from "../assets/icons/logo3.svg";
import logo4 from "../assets/icons/logo4.svg";
import logo6 from "../assets/icons/logo6.svg";
import logo7 from "../assets/icons/logo7.svg";
import logo8 from "../assets/icons/logo8.svg";
import logo9 from "../assets/icons/logo9.svg";
import logo10 from "../assets/icons/logo10.svg";
import logo11 from "../assets/icons/logo11.svg";
import { useTranslation } from "react-i18next";

import "../styles/ClientsLogos.css";


const logos = [logo1, logo2, logo3, logo4, logo6, logo7, logo8, logo9, logo10, logo11];

const ClientLogos = ({ direction }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="slider-title-container">
        <p className="wow animate__animated animate__fadeInUp clients_text">
          {t("ourClients")}
        </p>
      </div>
      <Marquee direction={direction} speed={50} gradient={false}>
        {logos.map((logo, index) => (
          <img key={index} src={logo} alt={`logo-${index}`} className="logo" />
        ))}
      </Marquee>
    </>

  );
};


export default ClientLogos;
