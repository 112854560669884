import React, { useRef } from "react";
import '../../styles/Card.css';

import Card from "../CardNoPrice.js";
import ScrollButtons from "../ScrollButtons.js";

import { useTranslation } from "react-i18next";

const Commentaries = () => {
  const { t } = useTranslation();
  const scrollRef = useRef(null);
  return (
    <>
      <h2 className="depprice">{t("dependedPrice")}</h2>
      <div ref={scrollRef} className="cardSection centeredCards gorilla">
        <Card
          // h1='bestOffer'
          // className='top'
          h2={"FE"}
          // text={"youTubeText"}
          negotiable={"negotiable"}
          // price='negotiable'
          text1={"FEText1"}
          text2={"FEText2"}
          text3={"FEText3"}
          text4={"FEText4"}
          text5={"FEText5"}
          // text6={"youTubeText6"}
        />

        <Card
          h2={"VE2D"}
          // text={"twitter2Text"}
          // negotiable={"negotiable"}
          text1={"VE2DText1"}
          text2={"VE2DText2"}
          text3={"VE2DText3"}
          text4={"VE2DText4"}
        />
        <Card
          h2="AE"
          // text={"telegrammText"}
          // negotiable={"negotiable"}
          text1={"AEText1"}
          text2={"AEText2"}
          text3={"AEText3"}
          text4={"AEText4"}
          text5={"AEText5"}
          text6={"AEText6"}
        />
        <Card
          h2="VE3D"
          // text={"ttText"}
          // negotiable={"negotiable"}
          text1={"VE3DText1"}
          text2={"VE3DText2"}
          text3={"VE3DText3"}
          text4={"VE3DText4"}
        />
        <Card
          h2={"DesignForProject"}
          text={"DesignForProjectText"}
          // negotiable={"negotiable"}
          text1={"DesignForProjectText1"}
          text2={"DesignForProjectText2"}
          text3={"DesignForProjectText3"}
          // text4={"Шрифт – от 5$/шт."}
        />
      </div>
      <div className="card5">
        <ScrollButtons scrollRef={scrollRef} />
      </div>
    </>
  );
};

export default Commentaries;
