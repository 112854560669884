import React, { useRef } from "react";
import '../../styles/Card.css';
import Card from "../CardTime.js";
import ScrollButtons from "../ScrollButtons.js";
import { useTranslation } from "react-i18next";

const ShillCard = () => {
  const scrollRef = useRef(null);
  const { t } = useTranslation();


  return (
    <>
      <h2 className="depprice deppriceM">{t('traficH12')}</h2>

      <div ref={scrollRef} className="cardSection centeredCards ">
        <Card
          // h1='bestOffer'
          h2='TandItrafic'
          text='TandItraficText'
          price='10000+'
          text1={'TandItraficText1'}
          text2={'TandItraficText2'}
          text3={'TandItraficText3'}
          text4={'TandItraficText4'}
          text5={'TandItraficText5'}
        />
        <Card
          // h1='bestOffer'
          h2='Mixtrafic'
          text='MixtraficText'
          price='5000+'
          text1={'MixtraficText1'}
          text2={'MixtraficText2'}
          text3={'MixtraficText3'}
          text4={'MixtraficText4'}
          text5={'MixtraficText5'}
        />
        <Card
          // h1='bestOffer'
          h2='Bottrafic'
          text='BottraficText'
          price='8000+'
          text1={'BottraficText1'}
          text2={'BottraficText2'}
          text3={'BottraficText3'}
          text4={'BottraficText4'}
          text5={'BottraficText5'}
        />



      </div>
      <div className="card5">
        {/* <ScrollButtons scrollRef={scrollRef} /> */}
      </div>
    </>
  );
}

export default ShillCard;