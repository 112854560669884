import React, { useRef } from "react";
import '../../styles/Card.css';

import Card from "../CardNoPrice.js";
import Card2 from "../CardNoPriceNobutton.js";
import ScrollButtons from "../ScrollButtons.js";

import { useTranslation } from "react-i18next";

const CardS = () => {
  const { t } = useTranslation();
  const scrollRef = useRef(null);
  return (
    <>
      <h2 className="depprice">{t("dependedPrice")}</h2>
      <div ref={scrollRef} className="cardSection centeredCards gorilla">
        <Card2
          // h1='bestOffer'
          // className='top'
          h2={"Tokenomich2"}
          text={"Tokenomictext"}
          negotiable={"negotiable"}
          // price='negotiable'
          text1={"Tokenomictext1"}
          text2={"Tokenomictext2"}
          text3={"Tokenomictext3"}
          text4={"Tokenomictext4"}
          text5={"Tokenomictext5"}
          text6={"Tokenomictext6"}
          text7={"Tokenomictext7"}
          text8={"Tokenomictext8"}
          text9={"Tokenomictext9"}
          text10={"Tokenomictext10"}
        />

        <Card
          h2={"Rateandaudith2"}
          text={"Rateandaudittext"}
          // negotiable={"negotiable"}
          text1={"Rateandaudittext1"}
          text2={"Rateandaudittext2"}
          text3={"Rateandaudittext3"}
          text4={"Rateandaudittext4"}
        />
        <Card
          h2={"Preparingh2"}
          text={"Preparingtext"}
          // negotiable={"negotiable"}
          text1={"Preparingtext1"}
          text2={"Preparingtext2"}
          text3={"Preparingtext3"}
          text4={"Preparingtext4"}
          text5={"Preparingtext5"}
          text6={"Preparingtext6"}
        />
        <Card
          h2={"Whitepaperh2"}
          text={"Whitepapertext"}
          // negotiable={"negotiable"}
          text1={"Whitepapertext1"}
          text2={"Whitepapertext2"}
          text3={"Whitepapertext3"}
          text4={"Whitepapertext4"}
        />
        <Card
          h2={"ITandCloudh2"}
          text={"ITandCloudtext"}
          // negotiable={"negotiable"}
          text1={"ITandCloudtext1"}
          text2={"ITandCloudtext2"}
          text3={"ITandCloudtext3"}
          text4={"ITandCloudtext4"}
          text5={"ITandCloudtext5"}
          text6={"ITandCloudtext6"}
        />
      </div>
      <div className="card5">
        <ScrollButtons scrollRef={scrollRef} />
      </div>
    </>
  );
};

export default CardS;
